import { CircularProgress } from '@mui/material';
import React, { useRef, useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ pdfUrl }) => {

  const pdfContainerRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [isPdfData, setIsPdfData] = useState(false)

  useEffect(() => {
    const renderPdfToContainer = async () => {
      try {
        const pdf = await pdfjs.getDocument(pdfUrl).promise;
        const numPages = pdf.numPages;
        if (pdf) {
          setIsPdfData(true)
        }
        for (let pageNum = 1; pageNum <= numPages; pageNum++) {
          const page = await pdf.getPage(pageNum);
          const viewport = page.getViewport({ scale: 1 });

          // const screenHeight = window.innerHeight;
          // const newScale = screenHeight / viewport.height;
          // setScale(newScale);
          const newScale = scale;
          const canvas = document.createElement('canvas');
          canvas.height = viewport.height * newScale;
          canvas.width = viewport.width * newScale;
          const context = canvas.getContext('2d');

          const renderContext = {
            canvasContext: context,
            viewport: page.getViewport({ scale: newScale }),
          };

          await page.render(renderContext);
          pdfContainerRef.current.appendChild(canvas);
        }
      } catch (error) {
        console.error('Error rendering PDF:', error);
      }
    };

    renderPdfToContainer();
  }, [pdfUrl]);
  return (
    <>
          {isPdfData ? <div className="scrollable-pdf-viewer">
            <div className='table-scrollY'
              style={{
                height: '30vh',
                width: '100%',
                overflowY: 'auto',
              }}
            >
              <div
                ref={pdfContainerRef}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  border: '2px solid',
                  borderRadius:'4px'
                }}
              />
            </div>
          </div> :
            <div className='text-center' style={{display:'grid',justifyContent:'center'}}>
              <CircularProgress />
            </div>}
    </>
  );
}

export default PdfViewer;
